import React, { useContext, useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import { REPAIR_CLAIM_STATUS, USER_ROLE } from "../../../utils/constant";
import {
  capitalizeAllFirstLetters,
  capitalizeFirstLetter,
  convertDateByTimezone,
  formatDateToDDMMMYYYY,
  getMonthithYearOptions,
} from "../../../utils/utils";
import _, { set } from "lodash";
import { toast } from "react-toastify";
import axios from "../../../service/axios";
import parseError from "../../../utils/ErrorParse";
import CustomButton from "../../common/Button/CustomButton";
import { FaPlus } from "react-icons/fa";
import CreateClaim from "./CreateClaim/CreateClaim";
import ClaimReqItem from "./ClaimReqItem";
import CsvGenerator from "../../csvGenerator/CsvGenerator";
import moment from "moment";
import AuthContext from "../../../helpers/authContext";
import { apiGetRequest } from "../../../helpers/Requests";
import { BASE_URL } from "../../../service/URL";


const ClaimReq = () => {
  var apiUrl = process.env.REACT_APP_API_URL;
  var accessToken = JSON.parse(secureLocalStorage.getItem("Token"));
  const [role] = useState(JSON.parse(secureLocalStorage.getItem("Role")));

  const { timezone } = useContext(AuthContext);

  const [resultData, setResultData] = useState();
  const [contractList, setContractList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const options = getMonthithYearOptions();

  //Filters
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [search, setSearch] = useState(null);

  const [error, setError] = useState({
    startDate: false,
    endDate: false,
  });

  const [reportDeleteLoader, setReportDeleteLoader] = useState(false);

  const [isShowModal, setIsShowModal] = useState(false);

  const addFiltersAndPagination = (url, params) => {
    let str = "";
    if (params?.page) {
      str = `page=${params.page}`;
    }
    if (params?.limit) {
      str = `${str}${str ? "&" : ""}limit=${params.limit}`;
    }
    if (params?.search !== null && params?.search !== undefined) {
      str = `${str}${str ? "&" : ""}search=${params.search}`;
    }
    if (
      params?.selectedStatus !== null &&
      params?.selectedStatus !== undefined
    ) {
      str = `${str}${str ? "&" : ""}status=${params.selectedStatus}`;
    }
    if (params?.startDate !== null && params?.startDate !== undefined) {
      str = `${str}${str ? "&" : ""}start_date=${params.startDate}`;
    }
    if (params?.endDate !== null && params?.endDate !== undefined) {
      str = `${str}${str ? "&" : ""}end_date=${params.endDate}`;
    }
    return str ? `${url}?${str}` : url;
  };


  const getData = async (obj = {}) => {
    try {
      const roleApi =
        role === "Technician" ||
        role === "Sales Advisor" ||
        role === "Service Advisor"
          ? "staff"
          : role;
      const url = addFiltersAndPagination(
        `${apiUrl}/${roleApi?.toLowerCase()}/repair`,
        obj
      );

      setIsLoading(true);

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const result = response.data;

      setResultData(result);
      // console.log(result);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  // useEffect(() => {
  //   getData();
  // }, []);

  const handleUpdateReportClaimStatus = (reportClaim) => {
    const prevReport = _.cloneDeep(resultData);
    if (reportClaim) {
      const agreementIndex = prevReport.findIndex(
        (x) => x.agreementNumber === reportClaim.agreementNumber
      );
      if (agreementIndex !== -1) {
        const index = prevReport[agreementIndex]?.repairs.findIndex(
          (x) => x._id === reportClaim._id
        );
        if (index !== -1) {
          prevReport[agreementIndex]?.repairs.splice(index, 1, {
            ...prevReport[agreementIndex]?.repairs?.[index],
            status: reportClaim.status,
            note: reportClaim.note,
          });
          setResultData([...prevReport]);
        }
      }
    }
  };

  const handleDeleteReportClaim = async (reportId, aggNum) => {
    try {
      setReportDeleteLoader(true);
      const roleApi =
        role === "Technician" ||
        role === "Sales Advisor" ||
        role === "Service Advisor"
          ? "staff"
          : role;
      const response = await axios.delete(
        `${apiUrl}/${roleApi?.toLowerCase()}/delete-repair/${reportId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const prevReport = _.cloneDeep(resultData);
      if (response) {
        const agreementIndex = prevReport.findIndex(
          (x) => x.agreementNumber === aggNum
        );

        if (agreementIndex !== -1) {
          const index = prevReport[agreementIndex]?.repairs?.findIndex(
            (x) => x._id === reportId
          );
          if (index !== -1) {
            prevReport[agreementIndex]?.repairs?.splice(index, 1);
            setResultData([...prevReport]);
          }
        }

        toast.success("Repair Claim Deleted Successfully!");
      }

      setReportDeleteLoader(false);
    } catch (err) {
      setReportDeleteLoader(false);
      const errorMEssage = parseError(err);
      toast.error(errorMEssage ?? "Something went wrong!");
      console.log("err", err);
    }
  };

  const getContractList = async () => {
    try {
      const response = await apiGetRequest(`${BASE_URL}/dealer/report-options`);
      if(response) {
        setContractList(response.data);
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(
      () =>
        getData({
          search,
          selectedStatus,
          startDate,
          endDate,
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(() => {
    getData({
      search,
      selectedStatus,
      startDate,
      endDate,
    });
    getContractList();
  }, [selectedStatus, endDate, startDate]);

  const dataForCsv = useMemo(() => {
    if (resultData === undefined || !resultData) return [];

    const dynamicHeadingRows = [
      [
        `Start Date: ${startDate || "-"}`,
        `End Date: ${endDate || "-"}`,
        `Claim Status: ${selectedStatus ?? "-"}`,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
    ];

    const columnTitles = [
      [
        "Agreement Number",
        "Name",
        "Email",
        "Phone Number",
        "Odometer Reading",
        "Categories",
        "Status",
        "Report Description",
        "Created At",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
    ];

    const dataRows = resultData?.flatMap((agreement) =>
      agreement.repairs.map((repair) => [
        repair.agreementNumber,
        repair.name ? capitalizeAllFirstLetters(repair.name) : "-",
        repair.email ? repair.email : "-",
        repair?.phoneNumber || "-",
        repair?.odometerReading?.toString() || "-",
        repair?.categories?.join(", ")?.replaceAll("_", " ") || "-",
        repair.status ? capitalizeFirstLetter(repair.status) : "-",
        repair?.reportDescription || "-",
        repair?.createdAt
          ? formatDateToDDMMMYYYY(
              convertDateByTimezone(repair.createdAt, timezone)
            )
          : "-",
        "",
        "",
        "",
        "",
        "",
        "",
      ])
    );

    return [...dynamicHeadingRows, ...columnTitles, ...dataRows];
  }, [resultData, startDate, endDate, selectedStatus]);

  return (
    <>
      <div>
        <div className="p-4">
          <div className="pb-2 w-100 d-flex justify-content-between align-items-center">
            <div>
              <h3>All Claims</h3>
            </div>
            <div className="header-button-claim">
              <div>
                <CsvGenerator data={dataForCsv} />
              </div>
              {role !== USER_ROLE.SUPER_ADMIN && (
                <div>
                  <CustomButton
                    icon={<FaPlus />}
                    cssClass="btn-effect-1 btn1"
                    label="ADD CLAIM"
                    clickHandler={() => setIsShowModal(true)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row pt-2">
            <div className="col-md-4" style={{ paddingLeft: "0px" }}>
              <label className="pb-2">
                Start Month
                {/* <span style={{ fontSize: "12px", marginLeft: "8px" }}>
                  (from the first day of this month)
                </span> */}
              </label>
              {/* {error.startDate && (
                <p className="error-message mb-2"> Select Start Date</p>
              )} */}
              <div className="input-item">
                <select
                  disabled={isLoading}
                  style={{ marginBottom: "0px" }}
                  className="nice-select"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    setError((prev) => ({
                      ...prev,
                      startDate: e.target.value === "",
                    }));
                  }}
                >
                  <option value="" selected disabled>
                    Select Start Month
                  </option>
                  {options.map((option) => (
                    <option key={option} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <label className="pb-2">
                End Month
                {/* <span
                  className=""
                  style={{ fontSize: "12px", marginLeft: "8px" }}
                >
                  (to the last day of this month)
                </span> */}
              </label>
              {/* {error.endDate && (
                <p className="error-message mb-2"> Select end Date</p>
              )} */}
              <div className="input-item">
                <select
                  style={{ marginBottom: "0px" }}
                  className="nice-select"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    setError((prev) => ({
                      ...prev,
                      endDate: e.target.value === "",
                    }));
                  }}
                  disabled={isLoading}
                >
                  <option value="" selected disabled>
                    Select End Month
                  </option>
                  {options.map((option) => (
                    <option key={option} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4" style={{ paddingRight: "0px" }}>
              <label className="pb-2">
                Status
                {/* <br />
                <span
                  style={{
                    visibility: "hidden",
                    fontSize: "12px",
                    marginLeft: "8px",
                  }}
                ></span> */}
              </label>
              <div className="input-item">
                <select
                  disabled={isLoading}
                  style={{ marginBottom: "0px" }}
                  className="nice-select"
                  value={selectedStatus}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                  }}
                >
                  <option value="all" selected>
                    All
                  </option>
                  {Object.entries(REPAIR_CLAIM_STATUS)
                    .filter(([key, value]) =>
                      role === USER_ROLE.ADMIN ? value !== "pending" : true
                    )
                    .map(([key, value], index) => (
                      <option key={index} value={value}>
                        {value === REPAIR_CLAIM_STATUS.ESCALATED ? "In Process" : capitalizeFirstLetter(value)}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row pt-2">
            <div className={"col-12 px-0"}>
              <div className="search-input">
                <i class="fa fa-search" aria-hidden="true"></i>
                <input
                  // disabled={isLoading}
                  className=""
                  id="search"
                  type="text"
                  name="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search by Registration Number..."
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table checkoverflow p-4 pt-0">
          <div className="mobileTable">
            <Table bordered className="overflow-set">
              <thead>
                <tr>
                  <th style={{ width: "5%" }}></th>
                  <th style={{ width: "10%" }}>Registration</th>
                  <th style={{ width: "10%" }}>Customer</th>
                  <th style={{ width: "10%" }}>Odometer Reading</th>
                  <th style={{ width: "15%" }}>Category</th>
                  <th style={{ width: "10%" }}>Status</th>
                  <th style={{ width: "10%" }}>Claim Date</th>
                  {(role === USER_ROLE.ADMIN ||
                    role === USER_ROLE.STAFF.SERVICE_ADVISOR) && (
                    <th className="text-center" style={{ width: "15%" }}>
                      Claim Status
                    </th>
                  )}
                  <th style={{ width: "15%" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <>
                    <div className="loader-container-claim">
                      <div className="loader-claim"></div>
                    </div>
                  </>
                ) : resultData?.length === 0 ? (
                  <tr>
                    <td colSpan={9}>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "100%" }}
                      >
                        <span className="error-text">No data found!</span>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {resultData?.length > 0 &&
                      resultData.map((item, index) => (
                        <ClaimReqItem
                          key={index}
                          group={item}
                          role={role}
                          handleUpdateReportClaimStatus={
                            handleUpdateReportClaimStatus
                          }
                          handleDeleteReportClaim={handleDeleteReportClaim}
                          reportDeleteLoader={reportDeleteLoader}
                          getData={getData}
                          resultData={resultData}
                          contractList={contractList}
                        />
                      ))}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {isShowModal && (
        <CreateClaim
          isShow={isShowModal}
          handleClose={() => {
            setIsShowModal(false);
            getData({
              search,
              selectedStatus,
              startDate,
              endDate,
            });
          }}
          role={role}
        />
      )}
    </>
  );
};

export default ClaimReq;
