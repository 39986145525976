import React from "react";
import { sumOfProgramsPrices } from "../../../../utils/utils";
import { EXTENDED_GST, EXTENDED_PST } from "../../../../utils/constant";

const ServicePricingSummary = ({
  isPricingVisible,
  structuredData,
  pst,
  gst,
}) => {
  const subtotalCost = structuredData?.reduce(
    (acc, item) => acc + sumOfProgramsPrices(item.programs),
    0
  );

  const subtotalRetail = structuredData.reduce((acc, item) => {
    const retailerPrice =
      Number(item.retailerPrice) || sumOfProgramsPrices(item.programs);
    const programPrices = Number(sumOfProgramsPrices(item.programs));
    const total = retailerPrice !== 0 ? retailerPrice : programPrices;
    return acc + total;
  }, 0);

  const gstCost = subtotalCost * (EXTENDED_GST / 100);
  const gstRetail = subtotalRetail * (gst / 100);
  const pstCost = subtotalCost * (EXTENDED_PST / 100);
  const pstRetail = subtotalRetail * (pst / 100);

  return (
    <table class="Terms-table table">
      <thead>
        <tr>
          <th scope="col">TERMS + ADDONS</th>
          {/* {isPricingVisible && (
            <> */}
          <th scope="col">DEALER COST</th>
          <th scope="col">RETAIL COST</th>
          <th scope="col">DEALER PROFIT</th>
          {/* </>
          )} */}
        </tr>
      </thead>
      <tbody>
        {structuredData.map((item, index) => (
          <>
            <tr key={index}>
              <td style={{ fontWeight: "bold" }}>
                {item.categoryName}
                {/* {isPricingVisible && (
                    <span style={{ fontWeight: "bold" }}>
                      {` (Total Cost: $${sumOfProgramsPrices(
                        item.programs
                      )})`}
                    </span>
                  )} */}
              </td>
            </tr>
            {isPricingVisible && (
              <>
                <tr>
                  <td>
                    {item.programs.map((service, i) => (
                      <tr>{service.programName}</tr>
                    ))}
                  </td>
                  <td>
                    {item.programs.map((service, i) => (
                      <tr>
                        {Number(service.priceDetails.price) > 0 ? (
                          `$${service.priceDetails.price}`
                        ) : (
                          <div style={{ visibility: "hidden" }}>Null</div>
                        )}
                      </tr>
                    ))}
                  </td>
                  <td colSpan={2} />
                  {/* <td>
                      {item.programs.map((service, i) => (
                        <tr>${service.priceDetails.price}</tr>
                      ))}
                    </td> */}
                  {/* <td> */}
                  {/* {isPricingVisible ? (
                    `$${(
                      checkboxState?.[key]?.["price"] -
                      otherItem.price
                    ).toFixed(2)}`
                  ) : (
                    <></>
                  )} */}
                  {/* </td> */}
                </tr>
                <tr>
                  <td style={{ paddingBottom: "10px" }}>
                    <h6 style={{ fontWeight: "600" }}>Summary Totals</h6>
                  </td>
                  <td>
                    <p
                      style={{
                        color: "red",
                        fontWeight: "600",
                        paddingBottom: "10px",
                      }}
                    >
                      ${sumOfProgramsPrices(item.programs)}
                    </p>
                  </td>
                  <td
                    style={{
                      color: "red",
                      fontWeight: "600",
                      paddingBottom: "10px",
                    }}
                  >
                    $
                    {item.retailerPrice !== null
                      ? item.retailerPrice
                      : sumOfProgramsPrices(item.programs)}
                  </td>
                  <td
                    style={{
                      color: "red",
                      fontWeight: "600",
                      paddingBottom: "10px",
                    }}
                  >
                    $
                    {item.retailerPrice !== null
                      ? item.retailerPrice - sumOfProgramsPrices(item.programs)
                      : 0}
                  </td>
                </tr>
              </>
            )}
          </>
        ))}
        {structuredData?.length > 0 && (
          <>
            <tr className="table-info">
              <td>Subtotal</td>
              {/* <td>${subtotalCost?.toFixed(2)}</td>
              <td>${subtotalRetail?.toFixed(2)}</td> */}
              <td>
                $
                {typeof subtotalCost === "number"
                  ? subtotalCost.toFixed(2)
                  : ""}
              </td>
              <td>
                $
                {typeof subtotalRetail === "number"
                  ? subtotalRetail.toFixed(2)
                  : ""}
              </td>
              <td>
                {`$${
                  (typeof subtotalRetail === "number"
                    ? subtotalRetail.toFixed(2)
                    : 0) -
                  (typeof subtotalCost === "number"
                    ? subtotalCost.toFixed(2)
                    : 0)
                }`}
              </td>
            </tr>
            <tr className="table-secondary">
              <td>GST ({gst}%)</td>
              <td>{gstCost?.toFixed(2) > 0 ? '$' + gstCost?.toFixed(2) : '-'}</td>
              <td>{gstRetail?.toFixed(2) > 0 ? '$' + gstRetail?.toFixed(2) : '-'}</td>
              <td></td>
            </tr>
            <tr className="table-secondary">
              <td>PST ({pst}%)</td>
              <td>{pstCost?.toFixed(2) > 0 ? '$' + pstCost?.toFixed(2) : '-'}</td>
              <td>{pstRetail?.toFixed(2) > 0 ? '$' + pstRetail?.toFixed(2) : '-'}</td>
              <td></td>
            </tr>
            <tr className="table-primary">
              <td>Grand Total</td>
              <td
                style={{
                  color: "red",
                  fontWeight: "600",
                  paddingBottom: "10px",
                }}
              >
                ${(subtotalCost + gstCost)?.toFixed(2)}
              </td>
              <td
                style={{
                  color: "red",
                  fontWeight: "600",
                  paddingBottom: "10px",
                }}
              >
                ${(subtotalRetail + gstRetail + pstRetail)?.toFixed(2)}
              </td>
              <td
                style={{
                  color: "red",
                  fontWeight: "600",
                  paddingBottom: "10px",
                }}
              >
                {`$${(
                  subtotalRetail +
                  gstRetail +
                  pstRetail -
                  (subtotalCost + gstCost)
                )?.toFixed(2)}`}
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export default ServicePricingSummary;
