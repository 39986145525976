export const POSTALCODE_PATTERN =
  /^[ABCEFGHJKLMNPRSTVXY][0-9][ABCEFGHJKLMNPRSTVWXYZ] ?[0-9][ABCEFGHJKLMNPRSTVWXYZ][0-9]$/;

export const EMAIL_REGEX_EXP = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const EXTENDED_GST = 5;
export const EXTENDED_PST = 0;

export const DEFAULT_TIMEZONE = "America/Edmonton";

export const LOADER_STATUS = {
  NA: -1,
  PENDING: 0,
  COMPLETE: 1,
};

export const USER_ROLE = {
  DEALER: "Dealer",
  ADMIN: "Admin",
  MANAGER: "Manager",
  SUPER_ADMIN: "SuperAdmin",
  STAFF: {
    SERVICE_ADVISOR: "Service Advisor",
    SALES_ADVISOR: "Sales Advisor",
    TECHNICIAN: "Technician",
  },
};

export const DEALER_EMPTY_OBJ = {
  email: "",
  address: "",
  city: "",
  name: "",
  postalCode: "",
  profileImage: "",
  region: "",
  state: "",
  faxNumber: "",
  phone: "+1",
  newPassword: "",
  password: "",
  service_manager_id: "",
  timezone: DEFAULT_TIMEZONE,
};

export const MANAGER_EMPTY_OBJ = {
  email: "",
  lastName: "",
  firstName: "",
  address: "",
  city: "",
  phone: "+1",
  postalCode: "",
  state: "",
  dealer_id: "",
};

export const SERVICE_MANAGER_EMPTY_OBJ = {
  email: "",
  lastName: "",
  firstName: "",
  phone: "+1",
  notes: "",
};

export const STAFF_EMPTY_OBJ = {
  email: "",
  lastName: "",
  firstName: "",
  address: "",
  city: "",
  phone: "+1",
  postalCode: "",
  state: "",
  dealer_id: "",
  role: "Service Advisor",
};

export const REPAIR_CLAIM_STATUS = {
  PENDING: "pending",
  ESCALATED: "escalated",
  ACCEPT: "accept",
  REJECT: "reject",
};

export const emptyPaginationObj = {
  page: 1,
  totalPages: 0,
  totalCount: 0,
};

export const STATUS_SELECT_OPTIONS = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

export const PAGINATION_OBJ = {
  page: 1,
  limit: 5,
  totalPages: 1,
  totalCount: 0,
  nextPage: 0,
  prevPage: -1,
};

export const ORDER_STATUS_SELECT_OPTIONS = [
  { label: "All", value: "all" },
  { label: "Pending", value: "pending" },
  { label: "Rejected", value: "rejected" },
  { label: "Complete", value: "complete" },
];

export const TIMEZONES = [
  { label: "Pacific Standard Time (PST): UTC -8", value: "America/Vancouver" },
  { label: "Mountain Standard Time (MST): UTC -7", value: "America/Edmonton" },
  { label: "Central Standard Time (CST): UTC -6", value: "America/Winnipeg" },
  { label: "Eastern Standard Time (EST): UTC -5", value: "America/Toronto" },
  { label: "Atlantic Standard Time (AST): UTC -4", value: "America/Halifax" },
  {
    label: "Newfoundland Standard Time (NST): UTC -3:30",
    value: "America/St_Johns",
  },
];

export const SERVICE_CATEGORIES = [
  {
    value: "65de06875a4edc7aca3a08b5",
    label: "MPP - Maintenance Protection Plan",
    isTiered: false,
    category: "automotive",
  },
  {
    value: "65de06875a4edc7aca3a08b6",
    label: "APP - Appearance Protection Plan",
    isTiered: false,
    category: "automotive",
  },
  {
    value: "65de06875a4edc7aca3a08b0",
    label: "Appearance Trust",
    isTiered: false,
    category: "automotive",
  },
  {
    value: "65de06875a4edc7aca3a08b1",
    label: "Oil Change Program",
    isTiered: false,
    category: "automotive",
  },
  {
    value: "65de06875a4edc7aca3a08ae",
    label: "Trust Care",
    isTiered: false,
    category: "automotive",
  },
  {
    value: "65de06875a4edc7aca3a08ad",
    label: "Key Fob",
    isTiered: false,
    category: "automotive",
  },
  {
    value: "65de06875a4edc7aca3a08b3",
    label: "Tire & Rim",
    isTiered: false,
    category: "automotive",
  },
  {
    value: "65de06875a4edc7aca3a08b2",
    label: "Anti Theft",
    isTiered: false,
    category: "automotive",
  },
  {
    value: "65de06875a4edc7aca3a08af",
    label: "Brakes & Rotors",
    isTiered: false,
    category: "automotive",
  },
  {
    value: "65de06875a4edc7aca3a08b4",
    label: "Trust Shield",
    isTiered: true,
    category: "automotive",
  },
  {
    value: "660313a5028f1749cc75e19b",
    label: "Ceramic",
    isTiered: false,
    category: "automotive",
  },
  {
    value: "66a18da6b5a9604e45495000",
    label: "Key Fob - Power Sport",
    isTiered: false,
    category: "powersports",
  },
  {
    value: "66a18a47b5a9604e45494ffe",
    label: "Anti Theft - Power Sport",
    isTiered: false,
    category: "powersports",
  },
  {
    value: "66a19420b5a9604e45495003",
    label: "Tire & Rim - Power Sport",
    isTiered: false,
    category: "powersports",
  },
  {
    value: "66b53fc07156d5658bd4b83e",
    label: "Drive Belt - Power Sport",
    isTiered: false,
    category: "powersports",
  },
  {
    value: "66b540097156d5658bd4b83f",
    label: "Brakes - Power Sport",
    isTiered: false,
    category: "powersports",
  },
  {
    value: "66b53f927156d5658bd4b83d",
    label: "Oil Change Program (ATV) - Power Sport",
    isTiered: false,
    category: "powersports",
  },
  {
    value: "66b547b67156d5658bd4b841",
    label: "Oil Change Program (UTV) - Power Sport",
    isTiered: false,
    category: "powersports",
  },
  {
    value: "669c06af44f848f100c127cf",
    label: "SPP - Power Sport Secure Protection Packag",
    isTiered: false,
    category: "powersports",
  },
  {
    value: "66b53ede7156d5658bd4b83c",
    label: "MPP (ATV) - Maintenance Protection Package",
    isTiered: false,
    category: "powersports",
  },
  {
    value: "66b544cd7156d5658bd4b840",
    label: "MPP (UTV) - Maintenance Protection Package",
    isTiered: false,
    category: "powersports",
  },
];

export const POWERSPORTS_SERVICE_CATEGORIES = [
  {
    value: "66a18da6b5a9604e45495000",
    label: "Key Fob - Power Sport",
    isTiered: false,
  },
  {
    value: "66a18a47b5a9604e45494ffe",
    label: "Anti Theft - Power Sport",
    isTiered: false,
  },
  {
    value: "66a19420b5a9604e45495003",
    label: "Tire & Rim - Power Sport",
    isTiered: false,
  },
  {
    value: "66b53fc07156d5658bd4b83e",
    label: "Drive Belt - Power Sport",
    isTiered: false,
  },
  {
    value: "66b540097156d5658bd4b83f",
    label: "Brakes - Power Sport",
    isTiered: false,
  },
  {
    value: "66b53f927156d5658bd4b83d",
    label: "Oil Change Program (ATV) - Power Sport",
    isTiered: false,
  },
  {
    value: "66b547b67156d5658bd4b841",
    label: "Oil Change Program (UTV) - Power Sport",
    isTiered: false,
  },
  {
    value: "669c06af44f848f100c127cf",
    label: "SPP - Power Sport Secure Protection Packag",
    isTiered: false,
  },
  {
    value: "66b53ede7156d5658bd4b83c",
    label: "MPP (ATV) - Maintenance Protection Package",
    isTiered: false,
  },
  {
    value: "66b544cd7156d5658bd4b840",
    label: "MPP (UTV) - Maintenance Protection Package",
    isTiered: false,
  },
];

export const REPAIR_ATTACHMENT_CATEGORIES = [
  { label: "General", value: "general" },
  { label: "Contract", value: "contract" },
  { label: "Dashboard Picture", value: "dashboard_picture" },
  { label: "Car General Picture", value: "car_general_picture" },
];

export const CLAIM_CATEGORY_TAGS = [
  { label: "Oil change program", value: "Oil change program" },
  { label: "Appearance Trust", value: "Appearance Trust" },
  { label: "Trust care", value: "Trust care" },
  { label: "Trust shield", value: "Trust shield" },
  { label: "Tire & Rim", value: "Tire & Rim" },
  { label: "Key fob", value: "Key fob" },
  { label: "Brakes and rotors", value: "Brakes and rotors" },
  { label: "Anti theft", value: "Anti theft" },
];

export const STATE_OPTIONS = [
  { label: "Alberta", value: "Alberta" },
  { label: "British Columbia", value: "British Columbia" },
  { label: "Manitoba", value: "Manitoba" },
  { label: "New Brunswick", value: "New Brunswick" },
  { label: "Newfoundland and Labrador", value: "Newfoundland and Labrador" },
  { label: "Nova Scotia", value: "Nova Scotia" },
  { label: "Ontario", value: "Ontario" },
  { label: "Prince Edward Island", value: "Prince Edward Island" },
  { label: "Quebec", value: "Quebec" },
  { label: "Saskatchewan", value: "Saskatchewan" },
];


export const PROVINCE_TAX_RATES = {
  "Alberta": {
    GST: 5,
    PST: 0,
  },
  "British Columbia": {
    GST: 5,
    PST: 7,
  },
  "Manitoba": {
    GST: 5,
    PST: 7,
  },
  "New Brunswick": {
    GST: 15,
    PST: 0,
  },
  "Newfoundland and Labrador": {
    GST: 15,
    PST: 0,
  },
  "Northwest Territories": {
    GST: 5,
    PST: 0,
  },
  "Nova Scotia": {
    GST: 15,
    PST: 0,
  },
  "Nunavut": {
    GST: 5,
    PST: 0,
  },
  "Ontario": {
    GST: 13,
    PST: 0,
  },
  "Prince Edward Island": {
    GST: 15,
    PST: 0,
  },
  "Quebec": {
    GST: 5,
    PST: 9.975,
  },
  "Saskatchewan": {
    GST: 5,
    PST: 6,
  },
  "Yukon": {
    GST: 5,
    PST: 0,
  },
};